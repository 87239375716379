import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Order,
  Stop,
  orderViewsT,
  OrderView,
  orderWithDetailsT,
} from "./types";
import * as notifications from "../../app/notifications";
import * as tPromise from "io-ts-promise";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { convertStringToDecimal } from "../../../helpers/parse-decimal";

const prefix = "data/orders";

type State = {
  myOrders: OrderView[];
  orderInView: OrderView | null;
};

const initialState: State = {
  myOrders: [],
  orderInView: null,
};

export type CreateOrderReq = Order;

const mapStop = (stop: Stop) => ({
  ...stop,
  time: stop.time ? stop.time.split("-").join(" - ") : null,
  lm: convertStringToDecimal(stop.lm).toString(),
  weight: stop.weight || null,
  colli: stop.colli || null,
  width: stop.width || null,
  height: stop.height || null,
  length: stop.length || null,
  cubicMeters: stop.cubicMeters || null,
});

export const loadMyOrders = createErrorReportingAsyncThunk(
  `${prefix}/load-my-orders`,
  async () => {
    const result = await fetch("/web-booking-api/orders");
    if (!result.ok) {
      throw new Error("Error loading data");
    }
    const data = result.json().then(tPromise.decode(orderViewsT));
    return data;
  }
);

export const createOrder = createAsyncThunk(
  `${prefix}/create-order`,
  async (
    orderPayload: {
      order: CreateOrderReq;
      files: Array<File>;
    },
    { dispatch }
  ) => {
    try {
      const { order, files } = orderPayload;
      const formData = new FormData();
      if (files.length) {
        for (let f of files) {
          formData.append("files", new Blob([f], { type: f.type }), f.name);
        }
      }
      const data = {
        ...order,
        quantity: order.quantity || null,
        unitId: order.unitId || null,
        pickups: order.pickups.map(mapStop),
        dropoffs: order.dropoffs.map(mapStop),
      };
      const encoded = orderWithDetailsT.encode(data);
      formData.append("data", JSON.stringify(encoded));
      const result = await fetch("/web-booking-api/orders", {
        method: "POST",
        body: formData,
      });
      if (result.ok) {
        dispatch(notifications.notify({ message: "Ordre oprettet" }));
      } else {
        throw new Error("Error creating order");
      }
    } catch (err) {
      dispatch(
        notifications.notify({
          message: "Der opstod en fejl under ordreoprettelsen",
          level: "error",
        })
      );
      throw err;
    }
  }
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMyOrders.fulfilled, (state, action) => {
      state.myOrders = action.payload;
    });
  },
});

export default slice.reducer;
